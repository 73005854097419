import React from 'react'
import {Map, Marker, Overlay} from 'pigeon-maps'
import { stamenTerrain } from 'pigeon-maps/providers'


class MapContainer extends React.Component {
    render() {
        return (
            <div style={{paddingLeft: '50px'}}>
                <Map  provider={stamenTerrain}
                    dprs={[1, 2]} // this provider supports HiDPI tiles
                    center={[-26.074357, 28.031084]} 
                    zoom={15} 
                    height={350}>
                    <Marker anchor={[-26.074357, 28.031084]} payload={1} onClick={({event, anchor, payload}) => {
                        window.open('https://goo.gl/maps/AhVoP8ePhL5BZkiT8', '_blank')
                    }}/>

                    <Overlay anchor={[-26.074357, 28.031084]}>
                        <img src='pigeon.jpg' height={158} alt=''/>
                    </Overlay>
                </Map>
            </div>
        );
    }
}

export default MapContainer
