import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
      <div className='custom-navbar'>
          <ul className="flex">
              <li className="ml-3 mr-3">
                  <a className="text-gray-400 hover:text-blue-500 open-sans" href="#about">About</a>
              </li>
              <li className="ml-3 mr-3">
                  <a className="text-gray-400 hover:text-blue-500 open-sans" href="#services">Services</a>
              </li>
              <li className="ml-3 mr-3">
                  <a className="text-gray-400 hover:text-blue-500 open-sans" href="#contact">Contact Us</a>
              </li>
          </ul>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
