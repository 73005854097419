import React from "react"

const ContactInfo = () => (
    <div className='contact-info flex box'>
            <div className='contact-info-container'>
                    <i className="mail-icon fa fa-envelope"></i>
                    <h3>GET IN TOUCH</h3>
                    <p>We look forward to receiving your enquiries.</p>
                <a className='contact-detail' href="mailto:admin@voxcoal.co.za">E: admin@voxcoal.co.za</a>
            </div>
    </div>
)

export default ContactInfo
