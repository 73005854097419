import React from "react"
import '../styles/global.css';
import Layout from "../components/layout"
import Header from "../components/header"
import LogoImage from "../components/logo-image"
import SEO from "../components/seo"
import Contact from "../components/contact"
import ContactInfo from '../components/contact-info'
import DiggerIcon from '../components/digger-icon'
import DumpTruckIcon from '../components/dump-truck-icon'
import MiningIcon from '../components/mining-gems-icon'
import {graphql} from 'gatsby'
import MapContainer from '../components/map-container'

const IndexPage = ({data}) => (
    <Layout>
        <SEO title="Home"/>
        <div className="animated fadeIn backgroud-image top-container">
            <div className='flex header-small md:flex-row-reverse'>
                <Header/>
            </div>
            <div className="box flex">
                <div className='logo-image'>
                    <LogoImage/>
                </div>
            </div>
            <div className='box flex'>
                <div className='hero-container'>
                    <span className='open-sans hero-text'>
                        {getContent(data).heroText}
                    </span>
                </div>
            </div>
        </div>
        <div id='about' className="about-container">
            <div>
                <div className='about-header-container box flex'>
                    <span className='open-sans-bold about-header'>About</span>
                </div>
                <div className='container mx-auto'>
                    <div className='flex flex-wrap'>
                        <div className='w-full about-body'>

                            Vox Coal (Pty) Ltd is a coal trading and supply chain management company that has been
                            supplying energy to the South African market since 2014.
                            <br/>
                            <br/>
                            The director and owner of Vox Coal (Pty) Ltd has had experience in the industry since
                            2008.
                            <br/>
                            <br/>
                            Due to the strength of our relationships and experience in the industry, we have
                            access
                            to multiple coal grades and sizes, logistics (road and rail) and other strategic
                            supply
                            lines.
                            <br/>
                            <br/>
                            Our client base is nationwide and spans across multiple industries. We are
                            represented
                            by Sales Affiliates throughout South Africa.
                            <br/>
                            <br/>
                            We pride ourselves on our relationships with our customers and our suppliers
                            and are
                            committed to offering a service that is both personal and excellent and a
                            product that
                            is of required quality.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='box flex divider'>
            <hr/>
        </div>
        <div id='services' className='info-container' style={{marginTop: '60px'}}>
            <div className='services-header-container'>
                <span className='open-sans-bold services-header'>{getContent(data).servicesHeader}</span>
            </div>
            <div className="container mx-auto items-center">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-1/3 mb-3 services-margin">
                        <div className="open-sans services-container services-container-height">
                            <div className="icon-container">
                                <div style={{width: '60px', height: '60px'}}>
                                    <MiningIcon/>
                                </div>
                            </div>
                            <div className="services-text-container">
                                <h2>{getContent(data).servicesHeaderOne}</h2>
                            </div>
                        </div>
                        <div className="">
                            <p>{getContent(data).servicesDescOne}</p>
                            <br/>
                            <p>{getContent(data).servicesDescOneb}</p>
                        </div>

                    </div>
                    <div className="w-full lg:w-1/3 mb-3 services-margin">
                        <div className="open-sans services-container">
                            <div className="services-container-height">
                                <div className="icon-container icon-container-left">
                                    <div style={{width: '60px', height: '60px'}}>
                                        <DumpTruckIcon/>
                                    </div>
                                </div>
                                <div className="services-text-container">
                                    <h2>{getContent(data).servicesHeaderTwo}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <p>{getContent(data).servicesDescTwo}</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 mb-3 services-margin">
                        <div className="open-sans services-container services-container-height">
                            <div className="icon-container">
                                <div style={{width: '75px', height: '75px'}}>
                                    <DiggerIcon/>
                                </div>
                            </div>
                            <div className="services-text-container">
                                <h2>{getContent(data).servicesHeaderThree}</h2>

                            </div>
                        </div>
                        <div className="">
                            <p>{getContent(data).servicesDescThree}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div id='contact' style={{ backgroundColor: '#595a5c'}}>
            <div className="container mx-auto">
                <div className="flex flex-wrap contact-container">
                    <div className="w-full lg:w-1/2 xl:w-1/3 mb-3">
                        <ContactInfo/>
                    </div>
                    <div className="w-full lg:w-1/2 xl:w-1/3 mb-3">
                        <Contact/>
                    </div>
                    <div className="w-full xl:w-1/3 mb-3 map">
                        <MapContainer></MapContainer>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

function getContent(data) {
    return data.dataJson.index
}

export const query = graphql`query MyQuery {
    dataJson {
        index {
            heroText
            servicesDescFour
            servicesDescOne
            servicesDescOneb
            servicesDescThree
            servicesDescTwo
            servicesHeader
            servicesHeaderFour
            servicesHeaderOne
            servicesHeaderThree
            servicesHeaderTwo
        }
    }
}
`

export default IndexPage
