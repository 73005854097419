import React from "react"

const Contact = () => (
    <form className='contact-form box flex' name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="field half first">
            <input placeholder='Name' type="text" name="name" id="name" />
        </div>
        <div className="field half">
            <input placeholder='Email' type="text" name="email" id="email" />
        </div>
        <div className="field">
            <textarea placeholder='Message' name="message" id="message" rows="6" />
        </div>
        <ul className="actions">
            <li>
                <input type="submit" value="Send Message" className="special actions-input" />
            </li>
        </ul>
    </form>
)

export default Contact
